import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import { Helmet } from "react-helmet";
import './../styles/Index.css';

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <div className="query-view box">

                <Helmet>
                    <title>Turkcell e-Şirket - e-Belge Sorgulama</title>
                    <body className="landing"></body>
                </Helmet>
                <Row className="text-center">
                    <img src="logo-dark.svg" className="index-logo" alt="Turkcell E-Şirket" />
                </Row>
                <Row>
                    <Col className="text-center">
                        Lütfen sorgulamak istediğiniz belge türünü seçin.
                    </Col>
                </Row>
                <hr />
                <Row xs="1">
                    <Col className="text-center">
                        <Button tag="a" href="/earsiv" outline block color="primary mb-1">e-Arşiv</Button>
                    </Col>
                    <Col className="text-center">
                        <Button tag="a" href="/emm" outline block color="primary mb-1">e-MM</Button>
                    </Col>
                    <Col className="text-center">
                        <Button tag="a" href="/esmm" outline block color="primary mb-1">e-SMM</Button>
                    </Col>
                    <Col className="text-center">
                        <Button tag="a" href="/ebilet-yolcu" outline block color="primary mb-1">Yolcu Bileti</Button>
                    </Col>
                    <Col className="text-center">
                        <Button tag="a" href="/ebilet-etkinlik" outline block color="primary mb-1">Etkinlik Bileti</Button>
                    </Col>
                </Row>
            </div>
        );
    }
}


export default Index;