
export const isEmpty = value => value === undefined || value === null || value === '';

export function alphaNumeric(value) {
    if (!isEmpty(value))
        for (let i = 0; i < value.length; i++) {
            let char = value.charAt(i);
            if (!((char >= 'a' && char <= 'z') || (char >= 'A' && char <= 'Z'))
                && char !== 'ç' && char !== 'ğ' && char !== 'ı' && char !== 'ö' && char !== 'ş' && char !== 'ü'
                && char !== 'Ç' && char !== 'Ğ' && char !== 'İ' && char !== 'Ö' && char !== 'Ş' && char !== 'Ü'
                && char !== ' '
                && !(char >= '0' && char <= '9')) {
                return "Sadece a-z, A-Z veya 0-9";
            }
        }
}