export function printHtml(html) {
    let printDiv = document.createElement('div');
    printDiv.id = 'printWrap';
    printDiv.innerHTML = html;
    document.body.appendChild(printDiv);

    setTimeout(() => {
        window.print();
        document.body.removeChild(printDiv);
    }, 250);
}


export function base64toBlob(b64Data, contentType, encoding, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;
    encoding = encoding || 'utf-8';

    let byteCharacters = atob(b64Data);
    let byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        let slice = byteCharacters.slice(offset, offset + sliceSize);

        let byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        let byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    let blob = new Blob(byteArrays, { type: contentType, encoding: encoding });
    return blob;
}