import React from 'react';
import { Button, Collapse, Alert, FormGroup, Label, Input, Form, FormText, Spinner } from 'reactstrap';
import { alphaNumeric, isEmpty } from '../common/validation';
import Recaptcha from 'react-gcaptcha';
import NumberFormat from 'react-number-format';
import queryString from 'query-string';
import { Helmet } from "react-helmet";

class Esmm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            identifier: '',
            voucherNumber: '',
            amount: '',
            showAlert: false,
            alertMessage: '',
            alertColor: "info",
            queryButtonText: "Sorgula",
            captchaReset: 0,
        }

        const { identifier, voucherNumber, amount } = this.props.match.params;
        if (identifier && voucherNumber && amount) {
            this.state.identifier = identifier;
            this.state.voucherNumber = voucherNumber;
            setTimeout(() => { this.handleNumberInput({ value: amount }) }, 0);
        } else {
            const qs = queryString.parse(window.location.search);
            if (qs.identifie && qs.amount) {
                this.state.identifier = qs.identifier;
                this.state.voucherNumber = '';
                setTimeout(() => { this.handleNumberInput({ value: qs.amount }) }, 0);
            }
        }

        this.handleChange = this.handleChange.bind(this);
        this.toggle = this.toggle.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.handleNumberInput = this.handleNumberInput.bind(this);
        this.captchaCallback = this.captchaCallback.bind(this);
        this.addGoogleCaptcha = this.addGoogleCaptcha.bind(this);
        this.addGoogleCaptcha();
    }

    addGoogleCaptcha() {
        const captchaLoaded = document.getElementById('captcha');
        if (!captchaLoaded) {
            const script = document.createElement("script");

            script.src = "https://www.google.com/recaptcha/api.js?onload=recaptchaLoaded&render=explicit&hl=tr&lang=tr";
            script.id = "captcha";
            script.async = true;
            script.defer = true;

            document.body.insertBefore(script, document.body.firstChild);
        }
    }

    captchaCallback(key) {
        this.setState({ captcha: key })
    }

    handleChange(event) {
        let { name, value } = event.target;
        value = value.trim();

        if (name === "identifier" && !Number.isInteger(Number(value))) {
            return
        }
        else if (name === "voucherNumber" && alphaNumeric(value)) {
            return
        }
        this.setState({
            [name]: event.target.value,
        });
    }

    handleNumberInput(values) {
        this.setState({ amount: values.value })
    }

    validateForm() {
        if (isEmpty(this.state.amount) ||
            isEmpty(this.state.identifier) ||
            isEmpty(this.state.captcha)) {
            this.setState({
                showAlert: true,
                alertMessage: "Bütün alanların doldurulması zorunludur.",
                alertColor: "danger",
                queryButtonText: "Sorgula"
            });
            return true;
        }
    }

    submitForm() {
        if (this.validateForm()) {
            return;
        }

        this.setState({ queryButtonText: "Yükleniyor" })
        const form = {
            identifier: this.state.identifier,
            voucherNumber: this.state.voucherNumber,
            amount: this.state.amount,
            captcha: this.state.captcha
        }
        const url = `v1/voucher/search?`;
        let query = Object.keys(form)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(form[k]))
            .join('&');

        fetch(url + query).then(response => {
            if (!response.ok || response.status === 204)
                throw Error(response.status)
            return response.json()
        }).then(res => {
            this.props.history.push({
                pathname: '/esmm/goruntule/',
                state: {
                    html: res.html,
                    id: res.id,
                    documentNumber: res.voucherNumber,
                    documentStatus: res.voucherStatus,
                    documentKey: res.voucherKey,
                    file: res.file,
                    pdf: res.pdf
                }
            });
        }).catch(status => {
            this.setState({
                showAlert: true,
                alertMessage: status.message === "204" ?
                    "Girdiğiniz e-SMM bilgileri ile eşleşen bir makbuz bulunmamaktadır." :
                    "Bir hata oluştu",
                alertColor: status.message === "204" ?
                    "info" :
                    "warning",
                queryButtonText: "Sorgula",
                captchaReset: this.state.captchaReset + 1,
                captcha: null
            });
        })
    }

    toggle() {
        this.setState(prevState => ({
            showAlert: !prevState.showAlert
        }));
    }


    render() {
        const isLoading = this.state.queryButtonText === "Yükleniyor";

        return (
            <div className="query-view offset-md-2 col-md-8">
                <Helmet>
                    <title>Turkcell e-Şirket - e-SMM Sorgulama</title>
                </Helmet>
                <script src="https://www.google.com/recaptcha/api.js?onload=recaptchaLoaded&render=explicit" async defer></script>
                <h1 className="h3 my-4">e-SMM Sorgula</h1>
                <Form className="box form-horizontal">
                    <Collapse isOpen={this.state.showAlert}>
                        <Alert color={this.state.alertColor} toggle={this.toggle}>
                            {this.state.alertMessage}
                        </Alert>
                    </Collapse>
                    <FormGroup row>
                        <Label for="identifier" className="col-sm-3 col-form-label">Satıcı VKN/TCKN</Label>
                        <div className="col-sm-9">
                            <Input
                                type="text"
                                name="identifier"
                                value={this.state.identifier}
                                onChange={this.handleChange}
                                required />
                            <FormText >10 Karakterli VKN veya 11 Karakterli TCKN numarası.</FormText>
                        </div>
                    </FormGroup>

                    <FormGroup row>
                        <Label for="voucherNumber" className="col-sm-3 col-form-label">e-SMM No</Label>
                        <div className="col-sm-9">
                            <Input
                                type="text"
                                name="voucherNumber"
                                value={this.state.voucherNumber}
                                onChange={this.handleChange}
                                required />
                        </div>
                    </FormGroup>

                    <FormGroup row>
                        <Label for="amount" className="col-sm-3 col-form-label">Brüt Tutar</Label>
                        <div className="col-sm-9">
                            <NumberFormat
                                className="form-control"
                                thousandSeparator="."
                                decimalSeparator=","
                                allowNegative={false}
                                value={this.state.amount}
                                isNumericString={true}
                                name="amount"
                                onValueChange={this.handleNumberInput}
                            />
                            <FormText>Brüt Tutar bilgisini makbuzun para birimi cinsinden giriniz.</FormText>
                        </div>
                    </FormGroup>

                    <div className="d-flex justify-content-end mb-3">
                        <Recaptcha
                            sitekey='6LcOrakUAAAAAEqePheKrCvOcOdlq8uVYsG8YW1c'
                            onloadCallback={this.captchaLoaded}
                            verifyCallback={this.captchaCallback}
                            reset={this.state.captchaReset}
                        />
                    </div>

                    <div className="d-flex justify-content-end">
                        <Button
                            color="primary"
                            onClick={this.submitForm}
                            disabled={isLoading}>
                            {isLoading && <Spinner size="sm" type="grow" />}
                            {this.state.queryButtonText}
                        </Button>
                    </div>

                </Form>
            </div>
        );
    }
}


export default Esmm;