import React from 'react';
import { Container, Navbar, NavbarBrand, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Nav } from 'reactstrap';
import { Link } from 'react-router-dom';
import './../styles/NavMenu.css';

export default class NavMenu extends React.Component {
  render() {
    return (
      <header>
        <Navbar className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3" light >
          <Container>
            <NavbarBrand tag={Link} to="/">
              <img src="logo-dark.svg" alt="Turkcell E-Şirket" />
            </NavbarBrand>
            <Nav>
              <UncontrolledDropdown nav inNavbar>

                <DropdownToggle nav caret>
                  Ürünler
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem tag="a" href="/earsiv">e-Fatura</DropdownItem>
                  <DropdownItem tag="a" href="/esmm">e-SMM</DropdownItem>
                  <DropdownItem tag="a" href="/emm">e-MM</DropdownItem>
                  <DropdownItem tag="a" href="/ebilet-yolcu">Yolcu Bileti</DropdownItem>
                  <DropdownItem tag="a" href="/ebilet-etkinlik">Etkinlik Bileti</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Container>
        </Navbar>
      </header>
    );
  }
}
