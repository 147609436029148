import React from 'react';
import { Route, Switch, Redirect } from 'react-router';
import Layout from './components/Layout';
import Earsiv from './components/Earsiv';
import Esmm from './components/Esmm';
import Emm from './components/Emm';
import Ebilet from './components/Ebilet';
import ResultView from './components/ResultView';
import Index from './components/Index';

export default () => (
  <Switch>
    <Route exact path='/' component={Index} />
    <Layout>
      <Switch>
        <Route exact path='/earsiv' component={Earsiv} />
        <Route exact path='/esmm' component={Esmm} />
        <Route exact path='/emm' component={Emm} />
        <Route exact path='/ebilet-yolcu' component={Ebilet} />
        <Route exact path='/ebilet-etkinlik' component={Ebilet} />
        <Route path="/earsiv/:identifier/:invoiceNumber/:amount" component={Earsiv} />
        <Route path="/esmm/:identifier/:voucherNumber/:amount" component={Esmm} />
        <Route path="/emm/:identifier/:receiptNumber/:amount" component={Emm} />
        <Route path="/ebilet-yolcu/:identifier/:ticketNumber/:amount" component={Ebilet} />
        <Route path="/ebilet-etkinlik/:identifier/:ticketNumber/:amount" component={Ebilet} />
        <Route path='/earsiv/goruntule/' component={ResultView} />
        <Route path='/esmm/goruntule/' component={ResultView} />
        <Route path='/emm/goruntule/' component={ResultView} />
        <Route path='/ebilet/goruntule/' component={ResultView} />
        <Redirect from="/ebilet" to='/ebilet-etkinlik' />
        <Redirect from="**" to='/earsiv' />
      </Switch>
    </Layout>
  </Switch>
);
