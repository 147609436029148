import React from 'react';
import { Button, Spinner } from "reactstrap";
import { printHtml, base64toBlob } from '../common/utils';
import FileSaver from 'file-saver'
import { Helmet } from "react-helmet";
import './../styles/ResultView.css';

const componentData = {
  earsiv: {
    returnUrl: "/earsiv",
    statusText: "Fatura Durumu",
    documentTitle: "Turkcell e-Şirket - e-Arşiv Fatura Sorgulama",
    showXmlButton: true
  },
  esmm: {
    returnUrl: "/esmm",
    statusText: "Makbuz Durumu",
    documentTitle: "Turkcell e-Şirket - e-SMM Sorgulama",
    showXmlButton: false
  },
  emm: {
    returnUrl: "/emm",
    statusText: "Makbuz Durumu",
    documentTitle: "Turkcell e-Şirket - e-MM Sorgulama",
    showXmlButton: true
  },
  ebilet: {
    returnUrl: "/ebilet",
    statusText: "Bilet Durumu",
    documentTitle: "Turkcell e-Şirket - e-Bilet Sorgulama",
    showXmlButton: false
  }
}
class ResultView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      documentPreview: null,
      loading: true,
      pdfButtonText: "PDF İndir",
      xmlButtonText: "XML İndir",
      documentNumber: ''
    }

    this.resultType = "earsiv";
    if (window.location.pathname.indexOf('/earsiv') > -1)
      this.resultType = "earsiv"
    else if (window.location.pathname.indexOf('/esmm') > -1)
      this.resultType = "esmm"
    else if (window.location.pathname.indexOf('/emm') > -1)
      this.resultType = "emm"
    else if (window.location.pathname.indexOf('/ebilet') > -1)
      this.resultType = "ebilet"

    this.documentKey = this.props.match.params.documentKey;

    this.printdocument = this.printdocument.bind(this);
    this.downloadPdf = this.downloadPdf.bind(this);
    this.downloadXml = this.downloadXml.bind(this);

    if (this.props.location.state && this.props.location.state.id !== undefined) {
      this.state.documentPreview = this.props.location.state.html;
      this.state.loading = false;
      this.state.documentStatus = this.props.location.state.documentStatus;
      this.state.documentNumber = this.props.location.state.documentNumber;
      this.state.file = this.props.location.state.file;
      this.state.pdf = this.props.location.state.pdf;
    } else {
      this.props.history.push(componentData[this.resultType].returnUrl);
    }
  }

  downloadPdf() {
    let blob = base64toBlob(this.state.pdf);
    FileSaver.saveAs(blob, this.state.documentNumber + ".pdf");
  }

  downloadXml() {
    let blob = base64toBlob(this.state.file);
    FileSaver.saveAs(blob, this.state.documentNumber + ".xml");
  }

  printdocument() {
    if (this.state.documentPreview == null || this.iframeRef == null) {
      return;
    } else {
      let html = this.iframeRef.contentWindow && this.iframeRef.contentWindow.document.querySelector('html');
      printHtml(html.innerHTML)
    }
  }

  render() {
    const { pdfButtonText, xmlButtonText, loading } = this.state;
    const { statusText, documentTitle, showXmlButton } = componentData[this.resultType];
    return (
      <div className="print-hide">
        <Helmet>
          <title>{documentTitle}</title>
        </Helmet>
        <div className="d-flex justify-content-end my-4">
          <Button color="warning mr-2" disabled={loading || pdfButtonText === "Yükleniyor"} onClick={this.downloadPdf}>
            {pdfButtonText === "Yükleniyor" && <Spinner size="sm" type="grow" />}
            {pdfButtonText}
          </Button>
          {
            showXmlButton &&
            <Button color="warning mr-2" disabled={loading || xmlButtonText === "Yükleniyor"} onClick={this.downloadXml}>
              {xmlButtonText === "Yükleniyor" && <Spinner size="sm" type="grow" />}
              {xmlButtonText}
            </Button>
          }
          <Button color="warning mr-2" disabled={loading} onClick={this.printdocument}>
            Yazdır
          </Button>
        </div>

        {
          loading ?
            <div className="text-center">
              <p className="h3">{this.state.error || "Yükleniyor"}</p>
              <span className="grow">
                <span className="loader loader-1" />
                <span className="loader loader-2" />
                <span className="loader loader-3" />
                <img src="turkcell-logo-white.png" alt="Turkcell Loading" />
              </span>
            </div>
            :
            <div className="mx-auto max-w-100" style={{ width: "830px" }}>
              <h1 className="h3">{statusText} {this.state.documentStatus}</h1>
              <iframe
                ref={ref => this.iframeRef = ref}
                id="preview"
                className="mx-auto max-w-100"
                title="document"
                width="830px"
                height="700px"
                srcDoc={this.state.documentPreview}
              />
            </div>
        }
      </div>
    );
  }
}

export default ResultView;
